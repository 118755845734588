import { getConnectionAsync, API } from 'o365-modules';

export default class OpenAi{
    public messageHistory:Array<Message> = [];
    private _systemMessage:Message = new Message("system",`You are an intelligent assistant in company "Omega 365" helping employees with their questions about documentation.  \
        When returning code do indicate programing language as JavaScript or CSharp after three backticks.\
        Each source has a name followed by colon and the actual data, quote the source name for each piece of data you use in the response.  \
        Answer the question using documentation provided from cognitive search results in the information sources below.  \
        For example, if the question is "What color is the sky?" and one of the information sources says "info123: the sky is blue whenever it's not cloudy", then answer with "The sky is blue"  \
        
        
        
        Treat Source names as part of content. \
        Respond in the same language as the user.
        If you cannot respond using the sources below, say that you don't know. \
        Be sure to respond only in English, Norwegian or Lithuanian. \
        If any other language is used ask the user to specify the language to converse in. \
    `);

    /* Removed lines from _systeMessge */

    // It's important to strictly follow the format where the name of the source is in square brackets at the end of the sentence, and only up to the prefix before the colon (":").  \
    // If there are multiple sources, cite each one in their own square brackets. For example, use "[info343][ref-76]" and not "[info343,ref-76]".  \
    // Never quote tool names as sources. \

    model:string = 'ChatGptV4';
    apiOptions:AiOptions = new AiOptions();
    onChatStreamUpdate:Function | undefined; 
    signalR:any | undefined;

    set systemMessage(pValue:string){
        this._systemMessage.content = pValue;
    }

    constructor(){

    }

    async getAiReponse(pSearch:string){
        const vMessages:Array<Message> = [];
        vMessages.push(this._systemMessage);
     if(this.messageHistory.length && this.apiOptions.includeHistory){
           // let vStop = Math.min(this.apiOptions.howManyMessagesToInclude,this.messageHistory.length);
            this.messageHistory.forEach((msg)=>{
                vMessages.push(msg);
                
            })
        }
        const vUserMsg = new Message("user",pSearch);
        this.messageHistory.push(vUserMsg);
        vMessages.push(vUserMsg);
        const vResults = await this._getResultsFormAi(vMessages);
        console.log(vResults);
        return vResults;
    }

    async searchContentItemsWithSummary(pSearch:string,pOrgUnit:string,pOrgUnitIdPath:string){
        const vMessages:Array<Message> = [];
        vMessages.push(this._systemMessage);
        
        if(this.messageHistory.length && this.apiOptions.includeHistory){
           // let vStop = Math.min(this.apiOptions.howManyMessagesToInclude,this.messageHistory.length);
            this.messageHistory.forEach((msg)=>{
                vMessages.push(msg);
                
            })
        }
        const vUserMsg = new Message("user",pSearch);
        this.messageHistory.push(vUserMsg);
        vMessages.push(vUserMsg);

        const vResults = await this._getContentItemResults(vMessages,pOrgUnit,pOrgUnitIdPath);
        const systemResponse = vResults.openAIReposnse.completions?.content[0]?.text ?? "";
        const links = vResults.sources.splice(0,Math.min(4,vResults.sources.length));
        
        const vAssistantMsg = new Message("assistant", systemResponse, links);
        this.messageHistory.push(vAssistantMsg);
        vMessages.push(vAssistantMsg);
        return vResults;
    }

    async searchContentItemsWithSummaryStream(
        pSearch: string,
        pOrgUnit: string,
        pOrgUnitIdPath: string,
        onChatStreamUpdate?: (chatUpdate: string | undefined) => void
    ): Promise<void> {
        // Define event function for caller
        this.onChatStreamUpdate = onChatStreamUpdate ?? this.onChatStreamUpdate;

        // Message content for AI
        const vMessages:Array<Message> = [];

        // Tell AI its purpose
        vMessages.push(this._systemMessage);
        
        // Include chat history
        if(this.messageHistory.length && this.apiOptions.includeHistory){
            this.messageHistory.forEach((msg)=>{
                vMessages.push(msg);
            })
        }

        // Tell AI user query
        const vUserMsg = new Message("user",pSearch);
        this.messageHistory.push(vUserMsg);
        vMessages.push(vUserMsg);

        // Create web socket hub for communication for word-by-word response from AI
        if(!this.signalR) {
            this.signalR = await this.bindHub();
        }
        // Send the message content to AI and expect response in the hub
        this.sendSignalRMessage(this.signalR,vMessages,pOrgUnit,pOrgUnitIdPath);
    }

    private updateChatHistoryAssistantStream(chatUpdate:string) {
        let lastMessage:Message = this.messageHistory[this.messageHistory.length - 1];

        if (!this.messageHistory.length || this.messageHistory[this.messageHistory.length - 1].role !== "assistant") {
            // Start a new assistant message if the last one isn't from the assistant
            const vAssistantMsg = new Message("assistant", "", []);
            this.messageHistory.push(vAssistantMsg);
        }

        lastMessage.content += chatUpdate;
    }

    private notifyChatStreamUpdate(chatUpdate:string) {
        this.updateChatHistoryAssistantStream(chatUpdate);
        if(this.onChatStreamUpdate){
            this.onChatStreamUpdate(chatUpdate);
        }
    }

    private sendSignalRMessage(signalR: any, pMessages:Array<Message>, pOrgUnit:string, pOrgUnitIdPath:string) {
        const requestData = JSON.stringify({
            temperature: this.apiOptions.temperature,
            nucleusSamplingFactor: this.apiOptions.nucleusSamplingFactor,
            messages: pMessages,
            idPath:pOrgUnitIdPath,
            orgunit:pOrgUnit
        });

        // Backend function binding
        // Fire and forget
        signalR.connection.send("ReceiveMessageAsync", requestData);
    }

    async bindHub() {
        try {
            const signalR = await getConnectionAsync('/nt/api/summarized-openapi/hub');
            signalR._connection.on('chatStream', (chatUpdate: string) => this.notifyChatStreamUpdate(chatUpdate));
            return signalR;
        } catch (error) {
            console.error("Failed to connect to SignalR hub:", error);
            throw new Error("SignalR connection failed. Please try again later.");
        }
    }




    private async _getContentItemResults(pMessages:Array<Message>,pOrgUnit:string,pOrgUnitIdPath:string){
         return await API.requestPost('/api/content/summarized-openapi', JSON.stringify({
                temperature: this.apiOptions.temperature,
                nucleusSamplingFactor: this.apiOptions.nucleusSamplingFactor,
                messages: pMessages,
                idPath:pOrgUnitIdPath,
                orgunit:pOrgUnit

            })
        );
    }

    private async _getResultsFormAi(pMessages:Array<Message>){
         return await API.requestPost('/nt/api/content/ai/chat', JSON.stringify({
                temperature: this.apiOptions.temperature,
                nucleusSamplingFactor: this.apiOptions.nucleusSamplingFactor,
                messages: pMessages,
                selectedModel: "TextDavinci003"

            })
        );
    }

}

class Message{
    role:"system" | "user" | "assistant" = 'assistant';
    content:string;
    links?:string[][];
    constructor(pRole:"system" | "user" | "assistant", pContent:string, pLinks?:string[][]){
        this.role = pRole,
        this.content = pContent;
        if (pLinks) {
            this.links = pLinks;
        }
    }
}

class AiOptions{
    temperature:string = '0.5';
    maxTokens: number = 800;
    nucleusSamplingFactor: string = '0.95';
    frequencyPenalty:  number = 0;
    presencePenalty:  number = 0;
    resultsCount: number = 1;
    includeHistory:boolean = true;
    howManyMessagesToInclude:  number = 10;
    stop: string = '\\n';  
}


// RECOMPILE
